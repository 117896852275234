<template>
  <b-modal
    id="clinic-feature-form-modal"
    size="md"
    centered
    hide-header
    hide-footer
    @hidden="onHidden"
  >
    <div class="header">
      <div class="header-content">
        <p>{{ form.id ? 'Atualizar' : 'Novo' }} vínculo de produto</p>
        <Close class="close-svg" @click="$bvModal.hide('clinic-feature-form-modal')" />
      </div>
    </div>
    <div class="body">

      <div class="row">
        <div class="col-12">
          <b-form-group>
            <label for="clinic">Clínica</label>
            <multiselect
              v-model="form.clinic"
              track-by="id"
              label="name"
              placeholder="Selecionar..."
              :options="clinics"
              :searchable="true"
              :internal-search="true"
              :allow-empty="false"
              :showLabels="false"
              class="with-border"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions">
                Nada por aqui ainda
              </template>
            </multiselect>
            <div v-if="verified && !form.clinic" class="custom-invalid-feedback">Campo obrigatório</div>
          </b-form-group>
        </div>
        <div class="col-12">
          <b-form-group>
            <label for="feature">Produto</label>
            <multiselect
              v-model="form.feature"
              track-by="id"
              label="name"
              placeholder="Selecionar..."
              :options="features"
              :internal-search="true"
              :searchable="true"
              :allow-empty="false"
              :showLabels="false"
              class="with-border"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions">
                Nada por aqui ainda
              </template>
            </multiselect>
            <div v-if="verified && !form.feature" class="custom-invalid-feedback">Campo obrigatório</div>
          </b-form-group>
        </div>
        <div class="col-12">
          <b-form-group>
            <label for="feature">Profissionais</label>
            <multiselect
              v-model="form.selectedProfessionals"
              track-by="value"
              label="name"
              placeholder="Selecionar..."
              :multiple="true"
              :options="professionals"
              :internal-search="true"
              :searchable="true"
              :allow-empty="true"
              :showLabels="false"
              class="with-border"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions">
                Nada por aqui ainda
              </template>
              <template #selection="props">
                <span class="ml-3"
                  v-b-tooltip.hover :title="props.values.map(option => option.name).join(', ')"
                >
                  {{ getLabel(props.values) }}
                  </span>
              </template>
            </multiselect>
            <!-- <div v-if="verified && !form.feature" class="custom-invalid-feedback">Campo obrigatório</div> -->
          </b-form-group>
        </div>
        <div class="col-12">
          <b-form-group>
            <label for="price">
              Preço
              <span class="help"> (opcional)</span>
            </label>
            <money
              id="price"
              v-model="form.price"
              class="form-control"
            />
          </b-form-group>
        </div>
      </div>

      <div class="actions">
        <b-button
          variant="outline-secondary"
          @click="$bvModal.hide('clinic-feature-form-modal')"
        >
          Cancelar
        </b-button>
        <b-button
          variant="primary"
          @click="form.id ? updateClinicProduct() : saveClinicProduct()"
        >
          Salvar
        </b-button>
      </div>
    </div>
  </b-modal>
</template>
<script>
import Close from '@/assets/icons/close.svg'
import ChevronDown from '@/assets/icons/chevron-down.svg'

export default {
  components: { Close, ChevronDown },
  props: {
    clinics: Array,
    clinicFeature: Object,
    features: Array,
  },
  data() {
    return {
      verified: false,
      form: {},
      professionals: [],
      clinic: null,
      selectedProfessionals: []
    }
  },
  methods: {
    getLabel(values){
      const label = values.length ? values.map(option => option.name).join(', ') : ''
      return label.length > 50 ? label.substring(0, 50) + '...' : label
    },
    getDefaultProps() {
      return {
        id: null,
        clinic: null,
        feature: null,
        price: 0,
        selectedProfessionals: null
      }
    },
    makeForm(val){
      this.form.id = val.id
      this.form.clinic = val.clinic
      this.form.feature = val.feature
      this.form.price = val.price
      this.form.selectedProfessionals = val.professionals?.map(el => { return {value: el.person.id, name:el.person.name}})
    },
    onHidden() {
      this.form = this.getDefaultProps()
      this.$emit('onHidden')
    },
    isValid() {
      this.verified = true
      return this.form.clinic
        && this.form.feature
    },
    getProps() {
      return {
        clinic_id: this.form.clinic.id,
        feature_id: this.form.feature.id,
        price: this.form.price,
        professionals: this.form.selectedProfessionals
      }
    },
    async saveClinicProduct() {
      if (!this.isValid()) return
      try {
        await this.api.createClinicFeature(this.getProps())
        this.$toast.success('Produto vincúlado com sucesso')
        this.$bvModal.hide('clinic-feature-form-modal')
      } catch(err) {
        this.$toast.error(err.message)
      }
    },
    async updateClinicProduct() {
      if (!this.isValid()) return
      try {
        await this.api.updateClinicFeature(this.form.id, this.getProps())
        this.$toast.success('Vincúlo atualizado com sucesso')
        this.$bvModal.hide('clinic-feature-form-modal')
      } catch(err) {
        this.$toast.error(err.message)
      }
    },
  },
  watch: {
    async clinicFeature(val) {
      const clinicId = val.clinic.id;
      const { data } = await this.api.getClinicDoctors(clinicId);
      this.professionals = data.doctors.map(p => {
        return {value: p.id, name:p.name}
      });
      if(val.id){
        this.makeForm(val);
      }else{
        this.getDefaultProps();
      }
    }
  }
}
</script>
<style lang="scss">
#clinic-feature-form-modal {

  .modal-body {
    padding: 0;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: var(--type-active);

    .header {
      border-bottom: 1px solid var(--neutral-200);

      .header-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 24px;

        .close-svg {
          height: 24px;
          width: 24px;
          fill: var(--neutral-500);
        }
      }
    }
    .body {
      padding: 24px;
      color: var(--type-active);
    }
    .actions {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;
      padding-top: 24px;
    }
  }

}
</style>
